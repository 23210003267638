import React from 'react';
import './App.css';
import backgroundVideo from "./video/bg.mp4"
import editor from "./img/editor.jpeg"
import idea from "./img/idea.jpeg"
import Bilibili from './img/bilibili.png'
import Youtube from './img/youtube.png'
import {Card, Col, Row, Button} from 'antd';
import Nav from './components/Nav'
import Footer from './components/Footer'

const {Meta} = Card;

function App() {


    return (
        <div className="App">
            <div className="links" >
                <a target="_blank" href="https://www.youtube.com/channel/UCUPv2sT532SAdKuK1P2OLZg/playlists">
                    <img alt="youtube" style={{width:"auto", height:"50px"}} src={Youtube}/>
                </a>
                <a target="_blank" href="https://space.bilibili.com/1542007665">
                    <img alt="bilibili" style={{width:"auto", height:"38px"}} src={Bilibili}/>
                </a>
            </div>
            <div className="banner">
                <Nav/>
                <video autoPlay loop muted id='video'>
                    <source src={backgroundVideo} type='video/mp4'/>
                </video>

                <header className="App-header">
                    <div className="hero-text">
                        <h2>VIDEO & SOCIAL MEDIA CONTENT PRODUCTION</h2>
                        <h4>For Businesses and Brands</h4>
                        <h5>商业视频及社交媒体内容制作</h5>
                    </div>
                </header>
            </div>

            <div className="main">
                <div className="main-content">
                    <h1 id="intro">Our Channel 频道介绍</h1>
                    <div className="post">
                        <p>
                            Presenting businesses and products, documenting people and places, at ShiningDays, we create
                            and
                            produce media content that is related to overseas Chinese lifestyle.
                        </p>
                        <p>
                            这里有北美生活动态，移民成长故事，海外房产信息，私家旅行攻略，新锐潮流产品和高尔夫运动分享。
                            我们专注创作海外华人生活相关的视频及社交媒体内容。
                        </p>
                        <Button onClick={()=>window.open("https://www.youtube.com/channel/UCUPv2sT532SAdKuK1P2OLZg/featured", "_blank")} style={{width:"150px", marginRight:"20px"}}>Youtube</Button>
                        <Button onClick={()=>window.open("https://space.bilibili.com/1542007665", "_blank")} style={{width:"150px", marginRight:"20px"}}>Bilibili</Button>
                    </div>

                    <h1 id="team">Our Team 制作团队</h1>

                    <div className="post">
                        <Row gutter={12} align="middle">
                            <Col span={12}>
                                <Card
                                    hoverable
                                    style={{width: 350, margin: "auto"}}
                                    cover={<img alt="editor" style={{height: "260px"}}
                                                src={editor}/>}
                                >
                                    <Meta
                                        title={<div><p>Howard Wang <br/>Editorial Producer</p> 老树，制片编辑</div>}
                                        description={<p>He is a market oriented story teller and cross-culture
                                            communicator. <br/><br/>
                                            一个把握市场风向的叙事者和跨文化的交流者。</p>}

                                    />
                                </Card>
                            </Col>
                            <Col span={12}>

                                <Card
                                    hoverable
                                    style={{width: 350, margin: "auto"}}
                                    cover={<img alt="idea" style={{height: "260px"}}
                                                src={idea}/>}
                                >
                                    <Meta
                                        title={<div><p>Steven Hou<br/>Director of Creative Photography J.H.</p> 影像创意总监
                                        </div>}
                                        description={<p>He believes that “a good picture can tell a story better than a
                                            million words.” <br/><br/>他坚信一段好的影像胜过千言万语。</p>}
                                    />
                                </Card>

                            </Col>
                        </Row>


                    </div>

                    <h1 id="work">Our Work 分类视频</h1>
                    <div className="post">
                        <Row gutter={2}>
                            <Col span={6}>
                                <Card
                                    onClick={()=>window.open("https://www.youtube.com/watch?v=c8T-qqdtZ8g&list=PLDs2_GfAhGFn3-3oc3VUWl0aspPUbEOa1", "_blank")}
                                    hoverable
                                    cover={<img alt="life" src="https://www.commonwealthfund.org/sites/default/files/styles/countries_hero_desktop/public/country_image_Canada.jpg?h=f2fcf546&itok=HpXJ6X1n"/>}>
                                    Overseas Lifestyle <br/> 北美生活动态
                                </Card>
                            </Col>
                            <Col span={6}>
                                <Card
                                    onClick={()=>window.open("https://www.youtube.com/watch?v=4GPYSDhj6_E&list=PLDs2_GfAhGFlqg6hv4-lDeU_5kKI26obL", "_blank")}
                                    hoverable
                                    cover={<img alt="new" src="https://www.cnet.com/a/img/ANPDAoCi7g6zPCtgg_tHBF5sTh4=/1200x675/2020/03/20/3b8682c7-fc0c-4b28-822a-071cf9bd695a/macbook-pro-16-late-2019-7-de-8.jpg"/>}>Product
                                    Review <br/> 潮流新品评测</Card>

                            </Col>
                            <Col span={6}>
                                <Card
                                    onClick={()=>window.open("https://www.youtube.com/watch?v=DUWttfQhQW4&list=PLDs2_GfAhGFnDJ7ZjAcULf4HRSZYP4AH4", "_blank")}
                                    hoverable
                                    cover={<img alt="golf" src="https://images.fineartamerica.com/images/artworkimages/mediumlarge/2/1-close-up-of-golf-ball-and-club-on-course-visage.jpg"/>}>Golf
                                    Vlog <br/> 高尔夫 Vlog</Card>
                            </Col>
                            <Col span={6}>
                                <Card
                                    onClick={()=>window.open("https://www.youtube.com/watch?v=0iEQfL9740k&list=PLDs2_GfAhGFnUUPZ62BLpJdTYVRnfqZbX", "_blank")}
                                    hoverable
                                    cover={<img alt="travel" src="https://img.huffingtonpost.com/asset/55a57eca1900002500b86129.jpeg?ops=scalefit_720_noupscale"/>}>Travel
                                    Documentary <br/> 自驾旅行</Card>
                            </Col>
                        </Row>
                        <Row gutter={2}>
                            <Col span={8}>
                                <Card
                                    onClick={()=>window.open("https://www.youtube.com/watch?v=CHZkAJcL9TY&list=PLDs2_GfAhGFkKM4A66V3dX4EJXClsNsK6", "_blank")}
                                    hoverable
                                    cover={<img alt="immigrate" src="https://www.productivity.govt.nz/assets/Inquiries/immigration-settings/output-onlinepngtools-2__PadWzEyMDAsNjMwLCJGRkZGRkYiLDBd.png"/>}>
                                    Immigration Updates
                                    <br/> 老树聊移民
                                </Card>
                            </Col>
                            <Col span={8}>
                                <Card
                                    onClick={()=>window.open("https://www.youtube.com/watch?v=D5cBtnusDMM&list=PLDs2_GfAhGFmsZCK8xOdRW76w6-SEwkDY", "_blank")}
                                    hoverable
                                    cover={<img alt="invest" src="https://etimg.etb2bimg.com/photo/80400331.cms"/>}>Real
                                    Estate Investment
                                    <br/> 海外地产投资</Card>

                            </Col>
                            <Col span={8}>
                                <Card
                                    onClick={()=>window.open("https://www.youtube.com/watch?v=7iZ0AdD0xps&list=PLDs2_GfAhGFkFInyAb9ul0w9AIO-SgGzQ", "_blank")}
                                    hoverable
                                    cover={<img alt="interview" src="https://hatrabbits.com/wp-content/uploads/2018/04/design-thinking-interviews.jpg"/>}>People
                                    Interview <br/> 海外人物志</Card>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    );
}

export default App;
