import React from "react";
import './style.css';
import {WechatOutlined, TwitterOutlined, FacebookOutlined, InstagramOutlined, PhoneOutlined} from "@ant-design/icons";

export default function Footer() {

    return (
        <div className="footer">
            <h1>CONTACT US</h1>
            <p><WechatOutlined style={{fontSize: '30px', color: '#ffffff'}}/>
                <text>Greenfieldedu</text>
            </p>
            <p><TwitterOutlined style={{fontSize: '30px', color: '#ffffff'}}/>
                <text href="">@shingdays2021</text>
            </p>
            <p><FacebookOutlined style={{fontSize: '30px', color: '#ffffff'}}/>
                <a href="https://www.facebook.com/shining.days.3/" target="_blank">shining.days.3</a>
            </p>
            <p><InstagramOutlined style={{fontSize: '30px', color: '#ffffff'}}/>
                <a href="https://www.instagram.com/myshiningdays2021/" target="_blank">myshiningdays2021</a>
            </p>
            <p><PhoneOutlined style={{fontSize: '30px', color: '#ffffff'}}/>
                <a href="tel:1-888-888-5518">888-888-5518</a>
            </p>

            <div className="copyright">
                Copyright © 2022 Shining Days
            </div>

        </div>
    );
}