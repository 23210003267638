import React, {useEffect, useState } from "react";
import {AppstoreOutlined, TeamOutlined, YoutubeOutlined} from "@ant-design/icons";
import './style.css';

export default function Header() {
    const [black, setBlack] = useState(false);

    useEffect(() => {
        if (typeof window !== "undefined") {
            window.addEventListener("scroll", () =>
                setBlack(window.scrollY > 250)
            );
        }
    }, []);

    return (
        <div className={black? "menu-black" : "menu"}>
            <a className="title">SHINING DAYS</a>
            <div className="nav">
                <a href="#intro"><YoutubeOutlined/> Our Channel 频道介绍</a>
                <a href="#team"><TeamOutlined/> Our Team 制作团队</a>
                <a href="#work"><AppstoreOutlined/> Our Work 分类视频</a>
            </div>
        </div>
);
}